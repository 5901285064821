import styled from "styled-components";

const HomeComponent = styled.div`
  && .silck-slide-home .slick-slider.slick-initialized {
    width: 100%;
    margin: 0 auto;
    max-width: 1600px;
  }
  .without_animation {
    display: none;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list .product-main {
    margin: 0;
  }
  .banner-slider {
    /* position: absolute;
    top: 0;
    left: 0; */
    width: 100%;
    height: 100%;

    .slick-slider {
      height: 100%;
      width: 100%;
      div{
        height: 100%;
        width: 100%;

        img {
          position: static;
          max-width: 100%;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  div#media {
    width: 100% !important;
    margin: 0;
    padding: 0;
  }
  .home-page .container {
    width: 100% !important;
    max-width: 1440px !important;
  }
  .par-text {
    width: 100%;
    margin: 60px auto;
    max-width: 1234px;
  }
  .par-text {
    width: 100%;
    margin: 60px auto;
    max-width: 1234px;
  }
  .par-welcome {
    margin-bottom: 32px;
    h4 {
      font-size: 18px;
    }
  }
  /* .home-page {
    background-size: 100% 100%;
  } */
  .welcome-section {
    .text-welcome {
      /* transform: translateY(51px); */
      z-index: 1;
      position: relative;
    }
    .first_section_ {
      display: flex;
      .text-welcome {
        /* transform: translateY(51px); */
        z-index: 1;
        position: relative;
        .welcome-text {
          transform: translateX(53px);
        }
        h2 {
          padding: 50px 33px 0 0;
          font-size: 59px;
        }
      }
    }
  }
  .pycn-section {
    .text-welcome {
      position: relative;
      left: -77px;
      top: 60px;
      z-index: 9;
    }
    .maritime-text {
      padding: 13px 26px;
      width: 100%;
      transform: unset;
      p {
        width: 100%;
      }
    }
  }
   
  .welcome-section {
    margin-top: 97px;
    padding: 0 0px;
    @media (max-width: 1024px) {
      margin-top: 68px;
    }

    .welcome_banner {
      @media (max-width: 767px) {
        float: left;
        width: 100%;
      } 
      .image-welcome {
        overflow: hidden; 
        height: auto;
        background-size: cover !important;
        position: relative;
        padding: 45px 0 12%;
        padding-top: 60px;
        background-position-y: -90px !important;
        background-repeat: no-repeat !important; 

        &.welcome-Carousel-banner {
          padding: 0;
          
          @media (max-width: 1280px) {
            padding: 0;
            max-height: unset;
            min-height: unset;
          }

          @media (max-width: 767px) {
            padding: 0 !important;
            margin-top: 112px;
            margin-bottom: 0;
          }

          @media (max-width: 480px) {
            margin-top: 0px;
          }

          .banner-slider {
            position: static;

            .slick-slider {
              .slick-slide {

                &>div {
                  display: flex;
                }
              }
            }
          }
        }
        
        @media (max-width: 1400px) {
          height: auto;
        }
        @media (max-width: 1280px) {
          max-height: 560px;
          padding: 40px 0 100px 0;
          background-position-y: 0 !important;
        }
        @media (max-width: 992px) {
          padding: 70px 0 100px;
          max-height: 430px;
        }
        @media (max-width: 1024px) {
          margin-top: 110px;    min-height: 480px;
        }
        @media (max-width: 991px) {
         min-height: auto;
        }
        @media (max-width: 767px) {
          margin-top: 0;  
          min-height: 430px;
          margin-bottom: 150px;
          overflow: visible;
          background: ${props => props?.promotionBannerMobile ? `url(${props?.promotionBannerMobile}) !important` : ``};
          padding-top: 150% !important;
        }

        @media (max-width: 1024px) {
          .col-md-3 {
            margin-top: 0;
            max-width: 360px;
          }
        }

        iframe {
          max-width: 100vw !important;
          width: 100vw !important;
          position: absolute;
          left: 50%;
          transform: translate(-50%, -50%);
          top: 50%;
          height: 180%;
          margin-top: -120px;
          @media (max-width: 1440px) {
            margin-top: -100px;
          }
          @media (max-width: 1366px) {
            margin-top: -80px;
          }
          @media (max-width: 1024px) {
            margin-top: -40px;
          }
          @media (max-width: 991px) {
            margin-top: 0px;
          }
          @media (max-width: 767px) {
            min-width: 210vw;
            width: 210vw !important;
          }
        }
      }
    }
  }
  .welcome-section.discount_banner{ 
        .welcome_banner { 
          .image-welcome {
            overflow: hidden;
            height: auto;
            background-size: cover !important;
            position: relative;
            padding: 30% 0 0;
            background-repeat: no-repeat !important;
            background-position: unset!important; 
            @media (max-width: 1199px){
              min-height: auto;
              margin-bottom: 0;
            }
            @media (max-width: 1024px){ 
              margin-top: 110px;
            }
            @media (max-width: 480px){
            margin-top: 0px;
            }
          }
        }
    }
  .image-welcome {
    margin-top: 0px;
    @media (max-width: 767px){
      
    }
    .frontend_image {
      img {
        position: static;
        display: block;
        width: 100%;
        height: 100%;
        max-width: 100%;
      }
    }
    img {
      position: absolute;
      bottom: 120px;
      max-width: 40vw;
      right: 13vw;
      z-index: 9;
      @media (max-width: 1280px) {
        bottom: 70px;
      }
      @media (max-width: 991px) {
        max-width: 40vw;
        right: 7vw;
        bottom: 40px;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        right: 0;
      }
    }
  }

  .image-welcome.vedio3 {
    height: 600px;
    overflow: hidden;
    position: relative;
  }
  .home-page .image-welcome.vedio3 iframe {
    max-height: 662px;
    margin-top: 0;
    max-width: 100vw !important;
    width: 100vw !important;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
  }
  .welcome-section.pycn-section {
    margin-top: 0;
  }
  .question-text h1 {
    margin: 0 0 70px;
  }
  .welcome-section.gauratee-section {
    padding: 50px 0 5px;
    margin-top: 0px;
  }
  .carousel img {
    object-fit: contain;
  }

  .vedio3 {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    iframe {
      max-width: 100vw !important;
      width: 100vw !important;
      height: 728px;
      line-height: 1;
      display: block;
      min-width: 1025px !important;
      min-height: 622px !important;
    }
  }
  .vedio2 {
    background-size: cover !important;
    background-repeat: no-repeat !important;
    iframe {
      max-width: 100% !important;
      width: 100% !important;
      height: 728px;
      line-height: 1;
      display: block;
      min-width: 1105px !important;
      min-height: 622px !important;
    }
  }

  .home-page .image-welcome.vedio2 iframe {
    max-width: 1105px !important;
    width: 100% !important;
    height: 923px;
    max-height: 596px;
  }

  // 18-02-2022
  .container.order-detail-page.p-4 .couponstyle__CoupanStyle-sc-1y1fifq-0 {
    padding: 10px 40px;
  }

  .container.order-detail-page.p-4 h3 {
    background: rgb(0, 53, 106);
    margin: 0px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 15px 0px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    font-weight: 500;
  }

  .distributed_customer {
    margin: 30px 0 0;
  }
  .coupon_list_table th,
  .coupon_list_table td {
    font-size: 14px;
  }
  .container.order-detail-page.p-4 button:not(:last-child) {
    margin-right: 10px;
  }
  .order_table.address-list.order-list.auto-ship-order-table
    table.commission-table
    td.autoship-action
    button {
    background: transparent;
    border: none;
    svg {
      width: 17px;
      height: 17px;
      margin: 0;
      fill: #ffaa0f;
    }
  }
  td.autoship-action {
    align-items: center;
    display: flex;
  }
  .orders_date {
    display: flex;
    justify-content: space-between;
  }

  .product-main {
    .radio_class.selected_class {
      background: hsl(216deg 66% 24% / 7%);
    }
    .radio_class {
      align-items: center;
      border-bottom: 1px solid #969696;
      min-height: 75px;
      color: #000;
      display: flex;
      &:first-child {
        border-top: 1px solid #969696;
      }
    }
    .second_section .radio_class {
      min-height: auto;
    }
    .title_section {
      text-align: left;
      width: 100%;
      h3 {
        font-size: 14px;
        text-transform: capitalize;
        font-family: "Graphik-bold";
        margin-bottom: 2px;
        margin-top: 7px;
      }
      p {
        font-size: 12px;
        margin-bottom: 7px;
        line-height: normal;
      }
      button.btn.btn-larn {
        height: 36px;
        max-width: 120px;
        font-size: 14px;
        margin-top: 15px;
      }
    }
    .price_section {
      padding-right: 10px;
      width: 100%;
    }
    .price_section div {
      font-size: 14px;
      padding-right: 0;
      font-family: "Graphik-bold";
    }
    /* .second_section {
    position: absolute;
    bottom: 0;
    background: #fff;
    padding-bottom: 10px;
} */
    .content-product {
      padding-bottom: 15px;
      button.btn.btn-cart {
        color: #fff;
        margin-top: 10px;
        max-width: fit-content;
        padding: 8px 20px;
        border-radius: 30px;
        font-weight: 500;
        border: 2px solid var(--blue);
        &:hover{
          background-color: #fff;
          color: var(--blue);
        }
      }
      .second_section {
        .radio_class.custom_radio {
          padding: 8px 0;
        }
        .normal_radio_container.radio_class.custom_radio {
          &.add_to_the_class {
            padding-bottom: 25px;
          }
          h3.order {
            margin: 0;
          }
          span.MuiRadio-root {
            margin-top: 5px;
          }
          p.green_code {
            margin-bottom: 0;
          }
        }
        span.discount_smartship {
          font-size: 12px;
          bottom: 10px;
          padding-right: 0;
          /* @media (max-width: 767px){

} */
        }
      }
    }
  }
  .content-product {
    .normal_radio_container.radio_class.custom_radio.selected_class.add_to_the_class {
      padding-bottom: 25px;
    }
  }

  @media (max-width: 1320px) {
    .text-welcome h2 {
      font-size: 46px;
    }
    .product-main1 img {
      width: 100px;
    }
    .welcome-text.maritime-text h4 {
      font-size: 28px;
    }
  }
  @media (max-width: 1400px) {
    .welcome-section.gauratee-section .image-welcome iframe {
      min-width: 1192px !important;
    }
    .home-page .image-welcome {
      height: 570px;
    }
  }
  @media (max-width: 1366px) {
    .welcome-section.gauratee-section .welcome-text {
      padding: 5px 10px 20px;
      width: 380px;
      transform: translate(0px, 55px);
    }
    .pycn-section {
      .image-welcome {
        min-width: 1000px;
      }
      .text-welcome {
        position: relative;
        left: 0px;
        top: 0px;
      }
    }
    .text-welcome h2 {
      font-size: 52px;
    }
    .home-page .image-welcome {
      height: 560px;
    }
  }
  @media (max-width: 1280px) {
    .home-page .image-welcome {
      height: 520px;
      background-size: cover !important;
    }
  }
  @media (max-width: 1100px) {
    .text-welcome h2 {
      font-size: 35px;
    }
    .pycn-section .image-welcome {
      min-width: 780px;
    }
    .question-text h1 {
      margin: 0 0 90px;
      font-size: 45px;
      padding: 0;
    }
    .stay-touch-part h5 {
      top: 43px;
      margin-left: 0;
    }
    .stay-touch-part {
      max-width: 100%;
    }
    .form-subscribe {
      text-align: left;
    }
    // input#staticEmail {
    //     width: 66%;

    // }
    .form-subscribe .form-group {
      width: 92%;
    }
    .welcome-text.maritime-text h4 {
      font-size: 25px;
      width: 100% !important;
    }
  }
  @media only screen and (max-width: 1199px) {
    .with_animation {
      display: none;
    }
    .without_animation {
      display: block;
    }
    /* .welcome-section {
      margin-top: 68px !important;
    }
  */
    .home-page .image-welcome {
      height: 490px;
      overflow: hidden;
    }
    .welcome-section.gauratee-section .image-welcome iframe {
      min-width: 1189px !important;
      min-height: 632px !important;
    }
  }

  @media (max-width: 991px) { 
    .text-welcome h2 {
      margin: 15px 0 15px;
      float: left;
      color: #000;
    }
    .welcome-section .text-welcome {
      transform: translateY(0);
    }
    .pycn-section .image-welcome {
      min-width: 100%;
    }
    .welcome-text.maritime-text h4 {
      width: 100%;
    }
    .welcome-text {
      width: 100%;
      text-align: center;
      padding: 24px 49px;
    }
    .par-text {
      width: 100%;
      margin: 34px auto 0;
      max-width: 100%;
      @media (max-width: 767px) {
        padding: 0 10px;
      }
    }
    .welcome-section.gauratee-section {
      padding: 0px 0 15px;
    }
    .welcome-section.gauratee-section p {
      width: 100%;
      margin: 0 auto;
    }
    .question-text {
      margin-top: 6px;
    }
    .stay-touch-part h5 {
      top: 59px;
    }
    .welcome-text p {
      font-size: 15px;
    }
    .par-text p {
      font-size: 15px;
    }
    .product-main1 img {
      width: 100%;
      max-width: 300px;
    }
    .slider-main a.btn.btn-larn {
      font-size: 14px;
      line-height: 23px;
    }
    .btn-shop-all a {
      font-size: 14px;
      line-height: 23px;
    }
    .welcome-section.gauratee-section .welcome-text p {
      font-size: 14px;
    }
    .question-text h1 {
      color: #000;
      margin: 0 0 44px;
      font-size: 35px;
    }
    .welcome-section {
      margin-top: 0px !important;
    }
    .pycn-section .text-welcome,.welcome-section .image-welcome {
      float: left;
      width: 100%;
    }
     
    .slider-main {
      margin: 60px 0 30px;
    }
    .slider-main .slider-main.mt-3 {
      margin: 0px auto;
    }
    .welcome-text {
      transform: translate(0px);
    }

    .home-page .image-welcome {
      height: 300px;
    }
    .welcome_banner + .row {
      width: 100%;
      display: flex;
      margin: 0 auto 25px;
    }
    .welcome-section.gauratee-section .welcome-text {
      padding: 15px 0px;
      width: 100%;
      transform: translate(0px);
      margin-bottom: 18px;
    }
  }

  @media (max-width: 942px) {
    .form-subscribe .btn {
      margin-left: 0;
    }
    .form-subscribe label {
      font-size: 27px;
      font-weight: 800;
    }
    .form-subscribe .form-group {
      display: flex;
    }
    input#staticEmail {
      width: 100%;
      margin: 0 0 0 20px;
    }
    .form-subscribe .form-group {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    .form-subscribe .btn.btn-primary {
      width: 100%;
      margin: 0 auto;
    }
    .form-subscribe .form-group {
      display: flex;
      margin-bottom: 0px;
    }
    .form-subscribe .form-group {
      width: 100%;
    }
    input#staticEmail {
      width: 100%;
    }
    .product-main h5 {
      font-size: 13px;
    }
    .welcome-section.gauratee-section p {
      padding: 15px;
    }
    .form-subscribe label {
      font-size: 27px;
      padding-top: 14px;
    }
    .stay-touch-part h5 {
      top: 63px;
      font-size: 15px;
    } 
    .welcome-text {
      padding: 24px;
    }
  }

  @media (max-width: 480px) {
    .welcome-text p {
      font-size: 13px;
      line-height: 22px;
    }
    .par-text p {
      font-size: 13px;
      line-height: 24px;
    }
    .welcome-section.gauratee-section .welcome-text p {
      font-size: 13px;
    }

    .question-text h1 {
      font-size: 26px;
      padding: 0;
    }
    .stay-touch-part h5 {
      top: 35px;
    }
    .form-subscribe label {
      font-size: 28px;
      font-weight: 800;
    }
    .welcome-section {
      padding: 110px 0 0;
    }
    .welcome-section.pycn-section {
      padding: 0;
    }
    .welcome-text.maritime-text h4 {
      font-size: 20px;
    }
    .welcome-text h4 {
      font-size: 20px;
    }
    .text-welcome h2 {
      font-size: 20px;
    }
    .form-subscribe label {
      font-size: 20px;
      font-weight: 800;
    }
    .home-page .image-welcome {
      position: relative;
      height: 200px;
      background-size: cover !important;
      background-repeat: no-repeat !important;
      @media (max-width: 767px) {
        height: auto;
        padding: 10px 0;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        width: 100%;
        justify-content: center;
        align-items: center;
        align-content: center; 
      } 
    }
    .slider-main {
      margin: 30px 0;
    }
    .welcome-section.gauratee-section .text-welcome {
      transform: unset !important;
    } 
    .welcome-text.maritime-text {
      margin-top: 20px;
    }
    .welcome-section.gauratee-section .image-welcome {
      height: 250px;
      iframe {
        min-width: 100vw;
        height: auto !important;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 250px;
        min-height: 250px !important;
        margin: 0 !important;
      }
    } 
    .welcome-section.pycn-section {
      .image-welcome.vedio3 {
        height: 250px;
      }
      .image-welcome.vedio2 {
        height: 250px;
        margin-top: 20px;
        iframe {
          min-width: 455px !important;
          min-height: 255px !important;
        }
      }
    }
  }

  @media (min-width: 767px) {
    .slider-main * {
      overflow: visible !important;
    }
    .slider-main .app_active svg.MuiSvgIcon-root,
    .slider-main .app svg.MuiSvgIcon-root {
      font-size: 30px;
    }
  }

  .content-product {
    padding-bottom: 20px;
    position: absolute;
    z-index: 9;
    top: 100%;
    background: #fff;
    width: 100%;
  }

  .slider-main {
    z-index: 9;
    position: relative;
  }

  .product-main {
    position: relative;
  }
  .home-page {
    overflow-x: hidden;
  }

  @media (max-width: 991px) {
    .product-main:hover .content-product {
      transform: translateY(-100px);
      visibility: hidden;
      opacity: 0;
      transition: 0.3s ease all;
      transform-origin: top;
    }
    .product-main .app_active + .content-product {
      transform: translateY(0px);
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 767px) {
    .product-main > div h5 + svg {
      width: 2em;
      height: 2em;
    }
    .slider-main .carousel-inner * {
      overflow: visible !important;
    }
    .welcome_banner .text-welcome .welcome-text {
      transform: translateY(420px);
      width: 100%;
      min-width: calc(100vw - 35px);
      display: block;
    }
    .fotter-list ul li a {
      font-size: 13px;
    }
    .welcome_banner + .row {
      margin: 0 auto 0px;
    }
    .welcome-section.gauratee-section .welcome-text {
      margin-bottom: 30px;
      transform: translate(0px, 0px);
    }
  }
  .product-main .content-product span {
    display: block;
    width: 100%;
    margin-top: 0px;
  }
`;

export default HomeComponent;
