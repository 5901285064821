import Link from 'next/link';
import router, { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import api from '../../../api/Apis';
import Slider from "react-slick";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { IconButton } from '@material-ui/core';
import HomeComponent from './HomePageStyle';
import parse from 'html-react-parser';
import VedioPlayer from '@Components/Common/VedioPlayer';
import { Parallax } from 'react-scroll-parallax';
import SvgAnimationBackground from '@Components/Common/SvgAnimationBackground';
import SubscribSection from '@Components/Common/SubscribSection';
import { toast } from 'react-toastify';
import { SecondSection } from 'src/pages/[page]/product/common';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClassNames from 'classnames';


export const AddCardButtonText = (is_stock_available, costtype, id) => {
    if (is_stock_available === 'True') {
        const findData = _.find(costtype, { id: id });
        if (findData?.id === id && findData?.value === "AutoShip") {
            return 'Subscribe';
        }
        return 'Add to Cart'
    } else {
        return "Sold Out"
    }
}

const Home = ({ icon, homePageData, setshowloader, minCartAutoshipCheck, smartShipCheck, setcounts, setInnerLoader, ...props }) => {
    const [productData, setProductData] = useState([]);
    const [costtype, setcosttype] = useState([]);
    const [productvariantid, setproductvariantid] = useState();
    const [Logintoken, setToken] = useState()
    const [product, setproduct] = useState();
    const [active, setActive] = useState(false);
    const [getTokenDat, setTokenDat] = useState()
    const [isActive, setIsActive] = useState("false");
    const [addNotify, setAddNotify] = useState('')

    const route = useRouter();
    const store = route?.query?.page || "us";
    const router = useRouter()
    const { id, name, page } = router.query;
    useEffect(() => {
        getAllProduct(store);
    }, [store])

    const getAllProduct = async (store) => {
        // setInnerLoader(true)
        await api.getAllProduct(store).then((res) => {
            const allvalues = _.map(res?.data?.products, (row) => ({
                id: row.id,
                value: "Normal",
            }));
            setcosttype(allvalues);
            setProductData(res?.data?.products);
        }).catch(() => {
        })
    }
    const sliders = useRef();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: false,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // vertical: true,
                    dots: true
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    // vertical: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    // vertical: true,
                    initialSlide: 1

                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    // vertical: true,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 360,
                settings: {
                    slidesToShow: 1,
                    // vertical: true,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const slidersBanner = useRef();
    const settings1 = {
        dots: false,
        infinite: true,
        // autoplaySpeed: 4000,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        pauseOnHover: false,
        cssEase: 'linear',
    };

    function next() {
        sliders?.current?.slickNext();
    }
    function previous() {
        sliders?.current?.slickPrev();
    }

    const handleAddCart = (id) => {
        const formData = {
            product_id: id, variant_id: productvariantid,
            token: Logintoken, quantity: 1,
            is_autoship: _.find(costtype, { id: id })?.value === 'AutoShip' ? true : false
        };
        api.addToCart(formData).then((res) => {
            if (res?.data?.code === 1) {
                setAddNotify('Added To Cart')
                setTimeout(() => {
                    setAddNotify('')
                }, 2000);
                // toast.success(res.data.message, { duration: 5 })
                props?.setshowminicart(true)
                // setcounts(res?.data);
            }
        })
    }

    const handleToggle = (id) => {
        if (isActive === id) {
            setIsActive('');
        } else {
            setIsActive(id);
        }
    };
    const Image = !(homePageData?.content[0]?.discount_banner_image_desktop) ? process.env.API_URL + homePageData?.content[0]?.banner_video_BGimage : homePageData?.content[0]?.discount_banner_image_desktop;
    const handleClick = (id) => {
        setInnerLoader(true)
        if (id === "8") {
            router.push("us/product/Super_Prime_120/8/");
            setInnerLoader(false)
        }
        if (id === "35") {
            router.push("us/product/BioticKaire+/35/");
            setInnerLoader(false)
        }
        if (id === "4") {
            router.push("us/product/Maritime_Prime_180/4/");
            setInnerLoader(false)
        }
        if (id === "7") {
            router.push("us/product/SilverKaire/7/");
            setInnerLoader(false)
        }
    }

    return (
        <>
            <HomeComponent promotionBannerMobile={homePageData?.content[0]?.discount_banner_image_mobile}>
                {
                    homePageData &&
                    <div className="home-page" >
                        <div className={homePageData?.content[0]?.discount_banner_image_desktop ? "welcome-section discount_banner" : "welcome-section"}>
                            <div className="welcome_banner">
                                <div className={homePageData?.content[0].banner_type !== "ImagesCarousel" ? "image-welcome" : "image-welcome welcome-Carousel-banner"}
                                    style={{
                                        // background: homePageData?.content[0]?.frontend_banner_image === null ? `url(${Image})` : 'none',
                                        cursor: homePageData?.content[0]?.discount_banner_image_desktop ? 'pointer' : 'default'
                                    }} onClick={() => {
                                        if (homePageData?.content[0]?.discount_banner_image_desktop) {
                                            router.push('/us/allProduct/')
                                        }
                                    }}>
                                    {!(homePageData?.content[0]?.discount_banner_image_desktop) &&
                                        <>
                                            {/* {homePageData?.imageCrousel?.length === 0 && <img src={`${process.env.API_URL}${homePageData?.content[0]?.banner_product_image}`} />} */}
                                            {homePageData?.content[0].banner_type === "Videos" && <img src={`${process.env.API_URL}${homePageData?.content[0]?.banner_product_image}`} />}
                                            {homePageData?.content[0].banner_type === "ImagesCarousel" &&
                                                homePageData?.content[0].frontend_banner_image !== null ?
                                                <div className='frontend_image'>
                                                    <img src={`${process.env.API_URL}${homePageData?.content[0]?.frontend_banner_image}`} />
                                                </div>
                                                :
                                                <div className="banner-slider">
                                                    <Slider ref={slidersBanner} {...settings1}>
                                                        {homePageData?.imageCrousel && homePageData?.imageCrousel?.map((row) => {
                                                            return (
                                                                <div className='image-crousel'>
                                                                    <img src={`${process.env.API_URL}${row?.banner_carousel_images}`} onClick={() => handleClick(row.banner_image_product_id)} />
                                                                </div>
                                                            )
                                                        })}
                                                    </Slider>
                                                </div>
                                            }
                                            {/* <img src={`${process.env.API_URL}${homePageData?.content[0]?.banner_product_image}`} /> */}
                                            {homePageData?.content[0].banner_type === "Videos" && homePageData?.content[0]?.banner_video_link &&
                                                <VedioPlayer url={homePageData?.content[0]?.banner_video_link} />
                                            }
                                        </>
                                    }

                                    <div className="container">
                                        {!homePageData?.content[0]?.discount_banner_image_desktop &&
                                            homePageData?.content[0].banner_type === "Videos" &&
                                            <div className="row">
                                                <div className="col-md-3">

                                                    <div className="text-welcome">
                                                        <div className="with_animation">
                                                            <Parallax y={[-50, 20]} tagOuter="figure" className="custom-class">
                                                                <div className="welcome-text" >
                                                                    {homePageData?.content?.length > 0
                                                                        ?
                                                                        parse(homePageData?.content[0]?.welcome_block)
                                                                        :
                                                                        <img src="/images/Welconme1.png" />
                                                                    }
                                                                </div>
                                                            </Parallax>

                                                        </div>
                                                        <div className="without_animation">
                                                            <div className="welcome-text" >
                                                                {homePageData?.content?.length > 0 ?
                                                                    parse(homePageData?.content[0]?.welcome_block)
                                                                    :
                                                                    <img src="/images/Welconme1.png" />
                                                                }
                                                            </div>
                                                        </div>
                                                        {/* <h2>{homePageData?.content[0]?.section1_heading && homePageData?.content[0]?.section1_heading}</h2> */}
                                                    </div>

                                                </div>
                                                <div className="col-md-9">

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="par-text">
                                        <div className="par-welcome">
                                            {homePageData?.content?.length > 0 ? parse(homePageData?.content[0]?.welcome_block)
                                                :
                                                <img src="/images/" />
                                            }
                                        </div>

                                        {homePageData?.content?.length > 0 ? parse(homePageData?.content[0]?.section1_content)
                                            :
                                            <img src="/images/" />
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="welcome-section pycn-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="image-welcome vedio2" style={{
                                            background: `url(${process.env.API_URL + homePageData?.content[0]?.section2_image})`
                                        }}>
                                            {homePageData?.content[0]?.section2_bgVedioUrl &&
                                                <VedioPlayer url={homePageData?.content[0]?.section2_bgVedioUrl} />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="text-welcome">
                                            <div className="with_animation">
                                                <Parallax y={[-10, 1]} tagOuter="figure" className="custom-class">
                                                    <div className="welcome-text maritime-text">
                                                        {homePageData?.content?.length > 0
                                                            ?
                                                            <>
                                                                {parse(homePageData?.content[0]?.section2_content)}
                                                                <div className="product-main1">
                                                                    <img src={process.env.API_URL + homePageData?.content[0]?.section2_Product_image} />
                                                                </div>

                                                                {/* <div className="lean-main">
                                                                <Link href={`/${store}/proven_benefits`} ><a className="btn btn-learn">Learn More</a></Link>
                                                    </div>*/}

                                                                {/*<button className="btn btn-larn learn_more"
                                                onClick={() => handleAddCart(homePageData?.content[0]?.id)} >Add To Cart</button>*/}

                                                            </>
                                                            :
                                                            <img src="/images/welcome2.png" />
                                                        }
                                                    </div>
                                                </Parallax>
                                            </div>
                                            <div className="without_animation">
                                                <div className="welcome-text maritime-text">
                                                    {homePageData?.content?.length > 0
                                                        ?
                                                        <>
                                                            {parse(homePageData?.content[0]?.section2_content)}
                                                            <div className="product-main1">
                                                                <img src={process.env.API_URL + homePageData?.content[0]?.section2_Product_image} />
                                                            </div>
                                                            {/*  <div className="lean-main">
                                                            <button className="btn btn-larn learn_more"
                                                                onClick={() => handleAddCart(homePageData?.content[0]?.id)} >Add To Cart</button>

                                                </div>*/}
                                                        </>
                                                        :
                                                        <img src="/images/welcome2.png" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="slider-main">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="slider-main mt-3">
                                            <div className="carousel slide media-carousel" id="media">
                                                <div className="carousel-inner">
                                                    <div className="silck-slide-home">
                                                        <IconButton className="button_pre" onClick={previous}><AiOutlineLeft /></IconButton>
                                                        <Slider ref={sliders} {...settings}>
                                                            {productData?.map((values, index) => {
                                                                if (values?.retail_open === true) {
                                                                    let names = values?.name.replaceAll('25%', '')
                                                                    let catname1 = names.replaceAll('\t', '')
                                                                    let catname = catname1.replaceAll(' ', '_')
                                                                    return (
                                                                        <li key={index + 1} onMouseEnter={() => setActive(values?.id)} onMouseLeave={() => setActive(null)}>

                                                                            <div className="product-main" key={index + 1}>
                                                                                <Link href={`/${store}/product/${catname}/${values?.id}`}>
                                                                                    <a className="thumbnail">
                                                                                        <img alt="" src={
                                                                                            values?.product_images[0]?.image
                                                                                                ?
                                                                                                `${process.env.API_URL}/${values?.product_images[0]?.image}`
                                                                                                :
                                                                                                "/images/no-image.jpg"}
                                                                                        />
                                                                                    </a>
                                                                                </Link>

                                                                                <h5>{values?.name}</h5>

                                                                                <div className={!(isActive === values?.id) ? "app" : "app_active"}>
                                                                                    <ArrowDropDownIcon onClick={() => handleToggle(values?.id)} />
                                                                                </div>
                                                                                <div className="content-product">
                                                                                    <>
                                                                                        <SecondSection data={{
                                                                                            costtype,
                                                                                            setcosttype,
                                                                                            minCartAutoshipCheck,
                                                                                            smartShipCheck,
                                                                                            id: values?.id,
                                                                                            productCost: values?.cost_price,
                                                                                            productSmartShip: values?.autoship_cost_price
                                                                                        }} />

                                                                                        <button className={ClassNames("btn btn-cart", { 'disable_true': !(values?.is_stock_available === "True") })}
                                                                                            disabled={!(values?.is_stock_available === "True")}
                                                                                            onClick={() => handleAddCart(values?.id, 1)}>{
                                                                                                AddCardButtonText(values?.is_stock_available, costtype, values?.id)}
                                                                                        </button>
                                                                                        {addNotify && <span className="error" style={{ color: 'green' }}>{addNotify}</span>}
                                                                                    </>
                                                                                </div>

                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                            })}
                                                        </Slider>
                                                        <IconButton className="button_next" onClick={next}><AiOutlineRight /></IconButton>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="btn-shop-all">
                                            {/*<Link className="nav-link" exact href={`/${store}/allProduct`}>
                                            <a className="btn btn-all">Shop All Products</a>
                                                    </Link>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="welcome-section gauratee-section">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-2">
                                        <div className="text-welcome">
                                            <div className="with_animation">
                                                <Parallax y={[-33, 1]} tagOuter="figure" className="custom-class">
                                                    <div className="welcome-text">
                                                        {homePageData?.content?.length > 0
                                                            ?
                                                            parse(homePageData?.content[0]?.section3_content)
                                                            :
                                                            <img src="/images/welcome3.png" />
                                                        }
                                                    </div>
                                                </Parallax>
                                            </div>
                                            <div className="without_animation">
                                                <div className="welcome-text">
                                                    {homePageData?.content?.length > 0
                                                        ?
                                                        parse(homePageData?.content[0]?.section3_content)
                                                        :
                                                        <img src="/images/welcome3.png" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="image-welcome vedio3" style={{
                                            background: `url(${process.env.API_URL + homePageData?.content[0]?.section3_image})`

                                        }}>
                                            {homePageData?.content[0]?.section2_bgVedioUrl &&
                                                <VedioPlayer url={homePageData?.content[0]?.section3_bgVedioUrl} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SubscribSection innerText={homePageData?.content[0]?.section3_footer} />
                        <SvgAnimationBackground />
                    </div>
                }
            </HomeComponent >
        </>
    )
}
export default Home;


